import React, { useRef } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderDots from "../components/SliderDots";

const TestimonialsSlider = ({
  backgroundColor,
  slides,
  textColor,
  title = "HAPPY WORDS FROM HAPPY CUSTOMERS",
  type = null,
}) => {
  const testimonialSlider = useRef(null);
  const testimonialSettings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: false,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: (dots) => (
      <SliderDots dots={dots} slider={testimonialSlider} useContainer={false} />
    ),
    useTransform: false,
  };

  return (
    <div
      className="section-customers"
      style={{
        backgroundColor,
        color: textColor,
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
				div.slick-dots div.cp-slider-control ul li button {
					border-color: ${textColor};
				}

				div.slick-dots div.cp-slider-control ul li.slick-active button {
					background-color: ${textColor};
				}

				div.slick-dots div.cp-slider-control a svg {
					color: ${textColor};
				}
			`,
        }}
      ></style>

      <div className="container">
        <div className="columns post-single ui-grid">
          <div className="column is-10">
            <p
              className="cp-heading"
              style={{
                color: textColor,
              }}
            >
              {title}
            </p>

            <Slider
              className="cp-slider"
              {...testimonialSettings}
              ref={testimonialSlider}
            >
              {slides.map((slide, i) => (
                <div key={slide.name}>
                  <p className="cp-text">“{slide.text}”</p>

                  {type === "brand" ? (
                    <>
                      <p className="cp-name" style={{ marginBottom: "1em" }}>
                        Name: {slide.name}
                      </p>
                      <p className="cp-name">Product: {slide.product}</p>
                    </>
                  ) : (
                    <p className="cp-name">– {slide.name}</p>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
