import React from "react";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

const SliderDots = ({ dots, slider, useContainer = true }) => (
  <div className="slick-dots">
    <div className={useContainer ? "container" : ""}>
      <div className="cp-slider-control">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();

            slider.current.slickPrev();
          }}
          className="cp-arrow-left"
        >
          <BsChevronLeft />
        </a>

        <ul style={{ margin: "0px" }}> {dots} </ul>

        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();

            slider.current.slickNext();
          }}
          className="cp-arrow-right"
        >
          <BsChevronRight />
        </a>
      </div>
    </div>
  </div>
);

export default SliderDots;
